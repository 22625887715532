<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New Category</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name (Thai) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="categoryRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEngName" class="col-sm-2 col-form-label">Name (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="categoryRequest.en_name" @blur="validationRule()"
										class="form-control" id="inputEngName">
									<small class="text-danger">{{validationData.en_name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label"> Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="categoryRequest.image" v-bind:src="image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'categoryCreate',
		metaInfo: {
            title: "Category Create",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				image: "",
				categoryRequest: {
					name: "",
					en_name: "",
					image: ""
				},
				validationData: {
					name: "",
					en_name: "",
					image: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				categoryStoreAction: 'categoryStoreAction',
			}),
			readImage(event) {
				this.categoryRequest.image = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('name', this.categoryRequest.name); 
				formData.append('en_name', this.categoryRequest.en_name); 
				formData.append('image', this.categoryRequest.image); 

				await this.categoryStoreAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'category-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', name: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.categoryRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.categoryRequest.en_name == "") {
					this.validationData.en_name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_name = ""
					isValidation.push(false)
				}
				if (this.categoryRequest.image == "") {
					this.validationData.image = "The image field is required."
					isValidation.push(true)
				} else {
					this.validationData.image = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>